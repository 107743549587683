import React from "react"
import { Link } from "gatsby"
import { getUser, isLoggedIn } from "../services/auth"

import Layout from "../components/common/layout"
import Image from "../components/common/image"
import SEO from "../components/common/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h1>Hello {isLoggedIn() ? getUser().name : "world"}!</h1>
    {isLoggedIn() ? (
      <>
        You are logged in, so check your{" "}
        <Link to="/app/profile">profile</Link>
      </>
    ) : (
        <>
          You should <Link to="/signin">log in</Link> to see restricted
          content
        </>
    )}
    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <Image />
    </div>
    <Link to="/page-2/">Go to page 2</Link>
  </Layout>
)

export default IndexPage
